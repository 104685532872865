import React from 'react'

/**
 * Add an anchor link
 *
 * @param {string}  id
 * @param {boolean} [topOffset=false]
 */
const Anchor = props => {
  return <span id={props.id} className="anchor" style={props.topOffset && {position: 'relative', top: '0'}}></span>
}

export default Anchor
